<template>
    <div>
      <Breadcrumbs main="" title="Affiliate Logs" />
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
                <b-row class="mt-2">
                <b-col class="col-md-3">
                  <b-row class="col-md-12">
                        <b-col>
                            Select Date Range
                        </b-col>
                    </b-row>
                  <b-row class="col-md-12">
                  <b-col>
                    <date-range-picker
                              class="col-md-12"
                              ref="picker"
                              opens="center"
                              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                              :singleDatePicker="false"
                              :timePicker="false"
                              :timePicker24Hour="true"
                              :showWeekNumbers="false"
                              :showDropdowns="true"
                              :autoApply="true"
                              v-model="dateRange"
                              @update="ChangedDate()"
                              :linkedCalendars="false"
                              :ranges="false"
                      >
                      <template v-slot:input="picker" style="min-width: 350px;">
                        <span style="font-size: 80%;"> {{ changeDateFormate(dateRange.startDate).format("D/M/YYYY") }} - {{ changeDateFormate(dateRange.endDate).format("D/M/YYYY") }}</span> 
                      </template>
                      </date-range-picker>
                  </b-col>
                  </b-row>  
                </b-col>
                <b-col class="col-md-6">
                    <b-row class="col-md-12">
                        <b-col>
                            Select Source : 
                        </b-col>
                    </b-row>
                    <b-row class="col-md-12">
                        <b-col>
                            <b-form-select name="source" size="sm" v-model="input.source" :options="source_opt"  required></b-form-select>
                        </b-col>
                        <b-col>
                            <b-button variant="primary" @click="SearchLogs()"> Filter </b-button>
                        </b-col>
                    </b-row>
                </b-col>
                </b-row>
                <b-row>
                <div class="col-md-12 mt-5" v-if="loading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                    <div class="loader-box">
                      <div class="loader-30"></div>
                    </div>
                </div> 
                </b-row>
                <div v-if="(items.length > 0)">
                  <b-row class="mt-3">
                  <b-col md="6" class="mb-3">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="col-md-5"
                        v-model="filter"
                        placeholder="Type to Search"
                        type="search"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
  
                  <b-col md="6" class="mb-3">
                    <b-form-group
                      label-cols="2"
                      label="Per page"
                      class="mb-0 datatable-select"
                    >
                      <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                        @change="filterLogs"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                
                <div class="table-responsive datatable-vue text-left">
                  <b-table
                    show-empty
                    stacked="md"
                    :items="items"
                    :filter="filter"
                    :fields="tablefields"
                    @filtered="onFiltered"
                    :per-page="perPage"
                  >
                  <template #table-caption>Affiliate Logs.</template>
                  <template #cell(clickid)="row">
                      <span class="res_text">{{row.item.clickid}}</span>
                  </template>
                  <template #cell(source)="row">
                      <span class="res_text">{{row.item.source}}</span>
                  </template>
                  <template #cell(event)="row">
                      <span class="res_text">{{row.item.event}}</span>
                  </template>
                  <template #cell(reqObj)="row">
                      <span class="res_text">{{row.item.reqObj}}</span>
                  </template>
                  <template #cell(resObj)="row">
                      <span class="res_text">{{row.item.resObj}}</span>
                  </template>
                  <template #cell(createdAt)="row">
                      <span class="res_text">{{changeDateFormate(row.item.createdAt).format("D/M/YYYY HH A")}}</span>
                  </template>
                  </b-table>
                </div>
              </div>
                <div v-else>
                    <b-row v-if="(loading != true)" class="mt-5">
                        <b-col class="text-center">
                            No data Found
                        </b-col>
                    </b-row>
                </div>
              <b-col md="12" class="mt-3 text-right p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="filterLogs('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="items.length >= 1" @click="filterLogs('next')">Next &raquo;</a>
                </div>
              </b-col>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import ManagerController from "../../../services/managerController"
  import ModeratorController from "../../../services/moderatorController";
  import DateRangePicker from 'vue2-daterange-picker';
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
  import moment from "moment";
  export default {
      name:'Users',
      components: {DateRangePicker},
      data(){
          return{
              dateRange:{startDate: new Date(),endDate: new Date()},
              input:{source:null,sdate:new Date(),edate:new Date()},
              source_opt:[{"value":null,"text":'All source'}],
              plans:[],
              items:[],
              totalRows:0,
              filter:null,
              currentPage: 1,
              perPage: 10,
              pageOptions: [5, 10, 50],
              loading:false,
              user_data:[],
              tablefields:[
                { key: "clickid", label: "Click ID", sortable: false },
                { key: "source", label: "Source", sortable: false },
                { key: "event", label: "Event", sortable: false },
                { key: "reqObj", label: "Request", sortable: false },
                { key: "resObj", label: "Response", sortable: false },
                { key: "createdAt", label: "CreatedAt", sortable: false },
        ]
          }
      },
      mounted(){
        this.getAffiliateSource()
        this.ChangedDate() 
        this.filterLogs()
      },
      methods: {
          getUserRole(){
            let user  = JSON.parse(window.localStorage.getItem('userinfo'))
            return user.role
          },
          async getAffiliateSource(){
              this.source_opt = [{"value":null,"text":'All source'}]
              let response 

              if(this.getUserRole() == 'manager'){
                response = await ManagerController.getSourceList()
              } else if(this.getUserRole() == 'moderator'){
                response = await ModeratorController.getSourceList()
              }

              if(response.result){
                  response.data.map((t)=>{
                    if(t && t != ''){
                      this.source_opt.push({"value":t,"text":t})
                    }
                  })
              }
          },
          onFiltered(filteredItems) {
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
        },
        changeDateFormate(d) {
        return moment(d);
        },
        SearchLogs(){
            this.currentPage = 1
            this.filterLogs()
        },
        async filterLogs(txt){
            this.loading = true
            if(txt == 'next'){this.currentPage ++}
            if(txt == 'prev'){
                if(this.currentPage != 1){
                this.currentPage --
                }
            }
            this.items = []
            let payload = {
            "currentPage": this.currentPage,"pageSize": this.perPage,
            "filters" : {   
                    "sdate": this.input.sdate,
                    "edate": this.input.edate,
                    "source": this.input.source
                }
            }
            // localStorage.setItem('affiliate_log', JSON.stringify(this.input))
            let response

            if(this.getUserRole() == 'manager'){
                response = await ManagerController.getAffiliateLogs(payload)
              } else if(this.getUserRole() == 'moderator'){
                response = await ModeratorController.getAffiliateLogs(payload)
              }
            
            if(response.result){
                this.items = response.data
            }
            this.loading = false
        },
        ChangedDate(){
          let sdate = new Date(this.dateRange.startDate).toISOString();
          let edate = new Date(this.dateRange.endDate).toISOString();
          this.input.sdate = sdate.substring(0, 10) + 'T00:00:00.000Z';
          this.input.edate = edate.substring(0, 10) + 'T23:59:59.000Z';
        },
      }
  }
  </script>


<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd;cursor: pointer;}
</style>
<style>
.daterangepicker {
  z-index: 100 !important;
}</style>